import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

export const AuthGuard: CanActivateFn = (route, state) => {

    if (inject(AuthService).isAuthenticated()) {
        return true;
    }

    if (window.location.href) {
        inject(LocalStorageService).setItem('redirectUrl', window.location.href)
    }

    inject(Router).navigate(['/auth/login']);
    return false;
};
