import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApplicationConfig, inject } from '@angular/core';
import {
	ApolloClientOptions,
	ApolloLink,
	InMemoryCache,
	split
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { environment } from '../environments/environment';
import { LocalStorageService } from './shared/services/local-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ToastrService } from '@shared/services/toastr.service';
import { ERROR_TOAST_STATE } from '@shared/const/toast-state';

export function apolloOptionsFactory(): ApolloClientOptions<any> {
	const localStorageService = inject(LocalStorageService);
	const token = localStorageService.getItemParsed('access_token');
	const toastr = inject(ToastrService);
	const router = inject(Router);
	const httpLink = inject(HttpLink).create({ uri: environment.GRAPHQL_URL });
	const wsLink = new WebSocketLink(
		new SubscriptionClient(environment.GRAPHQL_WS_URL, {
			connectionParams: {
				authorization: token ? `Bearer ${token.loginToken}` : ''
			}
		})
	);

	const errorAfterware = onError(
		({ networkError, graphQLErrors, response }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) => {
					console.error(
						`[GraphQL error]: Message: ${message}, Path: ${path}`
					);

					if (message.includes('Not logged in')) {
						toastr.show(ERROR_TOAST_STATE, 'Not logged in');
						localStorageService.clear();
						router.navigate(['/auth/login']);
					}
				});

			if (networkError)
				console.log(`[Network error]: ${JSON.stringify(networkError)}`);
		}
	);

	const retryLink = new RetryLink({
		delay: {
			initial: 1000,
			max: 15000,
			jitter: true
		},
		attempts: (count, operation, error) => {
			const doNotRetryCodes = [400, 401];
			const shouldRetry =
				count < 5 ||
				(error?.statusCode &&
					!doNotRetryCodes.includes(error.statusCode));
			if (!shouldRetry) {
				toastr.show(
                    ERROR_TOAST_STATE,
					'There was an error while processing your request. Please try again.'
				);
			}
			return shouldRetry;
		}
	});

	const authMiddleware = new ApolloLink((operation, forward) => {
		if (token) {
			const { loginToken, expires } = token;
			if (new Date().getTime() > new Date(expires).getTime()) {
				localStorageService.clear();
				router.navigate(['/auth/login']);
				toastr.show(ERROR_TOAST_STATE, 'Your session was expired');
			}

			operation.setContext({
				headers: new HttpHeaders().set(
					'Authorization',
					`Bearer ${loginToken}`
				)
			});
		}

		return forward(operation);
	});

	const authorizedHttpLink = authMiddleware
		.concat(retryLink)
		.concat(errorAfterware)
		.concat(httpLink);

	const link = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			);
		},
		wsLink,
		authorizedHttpLink
	);

	const listMergeNoDuplicates = (existing = [], incoming: any) => {
		console.debug(
			'[graphql.module.listMergeNoDuplicates] existing',
			existing
		);
		return existing.concat(
			incoming.filter((x: any) =>
				existing.every((y: any) => y.skuKey !== x.skuKey)
			)
		);
	};

	const cache = new InMemoryCache({
		typePolicies: {
			SkuList: {
				fields: {
					skus: {
						merge: listMergeNoDuplicates
					}
				}
			}
		}
	});

	return {
		link,
		cache,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
				nextFetchPolicy: 'cache-and-network'
			},
			query: {
				fetchPolicy: 'cache-first',
				errorPolicy: 'all'
			}
		}
	};
}

export const graphqlProvider: ApplicationConfig['providers'] = [
	Apollo,
	{
		provide: APOLLO_OPTIONS,
		useFactory: apolloOptionsFactory
	}
];
