import { Component } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ToastrService } from '@shared/services/toastr.service';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';

@Component({
	selector: 'app-toastr-modal',
	standalone: true,
	imports: [ModalComponent, AsyncPipe, SpinnerComponent],
	templateUrl: './toastr-modal.component.html',
	styleUrl: './toastr-modal.component.scss'
})
export class ToastrModalComponent {

	constructor(public toastrService: ToastrService) {
	}

    dismiss() {
        this.toastrService.dismiss();
    }

}
