import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./manager/manager.routes').then((mod) => mod.MANAGER_ROUTES)
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./auth/auth.routes').then((mod) => mod.AUTH_ROUTES)
	},

];
