<modal id="toastr-modal" [backdropClose]="false" [showBackdrop]="false">
	<div
		class="toastr py-3 px-6 flex flex-col gap-3"
		[class]="(toastrService.toastState$ | async)?.state"
	>

		<div class="flex justify-between items-end  gap-3">
            <div class='flex-1'>
                @if (toastrService.toastTitle$ | async; as title) {
                    <h3 class="text-lg font-semibold" [innerHTML]="title"></h3>
                }
                <p
                    class="text-md"
                    [innerHTML]="toastrService.toastMessage$ | async"
                ></p>

            </div>
			@if (toastrService.toastState$ | async; as state) {
				@if (state.isLoading) {
					<div class="flex justify-center">
						<app-spinner></app-spinner>
					</div>
				}
			}
		</div>
	</div>
</modal>
