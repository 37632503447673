import { Component, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { filter, switchMap } from 'rxjs';
import { RouteData } from './shared/models/route-data';
import { ToastrModalComponent } from '@shared/components/modals/toastr-modal/toastr-modal.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [CommonModule, RouterOutlet, ToastrModalComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {
	title = 'manage-web-app';
    routeData!: RouteData;

	constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly destroyRef: DestroyRef) {
		loadDevMessages();
		loadErrorMessages();
	}

    ngOnInit(): void {
        this.router.events.pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(e => e instanceof NavigationEnd),
          switchMap(() => this.activatedRoute.firstChild ? this.activatedRoute.firstChild.data : this.activatedRoute.data),
        ).subscribe((data: RouteData) => {
          this.routeData = data;
        })
      }

}
